<template>
  <div v-if="paygSubscription">
    <BaseCard>
      <header class="mb-8 md:flex md:items-center md:justify-between">
        <div class="flex items-center">
          <span
            class="flex flex-col items-center justify-center w-10 h-10 mr-4 text-white rounded-full bg-secondary"
          >
            <Icon
              width="24px"
              height="24px"
              view-box="0 0 24 24"
            >
              <Receipt />
            </Icon>
          </span>
          <h2 class="text-lg">
            Change Plans
          </h2>
        </div>
      </header>

      <div v-if="state === states.READY">
        <div class="grid w-full gap-3 mb-4 md:grid-cols-3">
          <div
            v-for="plan in currencyPlans"
            :key="plan.level"
            class="flex flex-col px-6 py-4 border rounded-lg"
          >
            <p class="pb-2">
              <span class="px-2 py-1 text-sm rounded bg-primary-200 hover:bg-gray-primary-400">
                {{ plan.name }}
              </span>
            </p>
            <div>
              <p class="mt-3 text-3xl font-bold leading-none">
                {{ formatted(plan.products.fixed.price.unit_amount / 12, selectedCurrency) }} <span class="text-lg font-normal text-gray-600">/month</span>
              </p>
              <p class="mt-3 leading-none text-gray-600">
                Billed at {{ formatted(plan.products.fixed.price.unit_amount, selectedCurrency) }} /year + <span class="text-xs">VAT</span>
              </p>
            </div>
            <div class="mt-6 space-y-4">
              <div class="px-4 py-3 border border-dashed rounded">
                <p class="flex items-center font-medium">
                  Assessment credits <span class="py-0.5 ml-2 inline-flex px-1 text-xs text-primary rounded bg-gray-300 w-8 justify-center items-center">
                    {{ numberFormat(plan.assessment_credits) }}
                  </span>
                </p>
                <p class="text-gray-600">
                  {{ formatted(plan.products.assessments.price.unit_amount, selectedCurrency) }} each thereafter
                </p>
              </div>

              <div class="px-4 py-3 border border-dashed rounded">
                <p class="flex items-center font-medium">
                  Candidate credits <span class="py-0.5 ml-2 inline-flex px-1 text-xs text-primary rounded bg-gray-300 justify-center items-center">
                    {{ numberFormat(plan.candidate_credits) }}                  </span>
                </p>
                <p class="text-gray-600">
                  {{ formatted(plan.products.candidates.price.unit_amount, selectedCurrency) }} each thereafter
                </p>
              </div>
            </div>

            <div v-if="plan.level === paygSubscription.plan.level">
              <BaseButton
                disabled
                variant="green"
                class="justify-center w-full mt-6"
              >
                Current Plan
              </BaseButton>
            </div>
            <div v-else-if="plan.level < paygSubscription.plan.level">
              <PAYGDowngradeButton
                class="mt-6"
                :plan="plan"
              />
            </div>
            <div v-else-if="plan.level > paygSubscription.plan.level">
              <PAYGUpgradeButton
                class="mt-6"
                :plan="plan"
                :billed="formatted(plan.products.fixed.price.unit_amount, selectedCurrency)"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        v-else-if="state === states.LOADING"
        class="my-8"
      >
        <Loader />
      </div>

      <div
        v-else-if="state === states.ERROR"
        class="my-8"
      >
        Sorry, an error occurred
      </div>
    </BaseCard>
  </div>
</template>

<script>
import Icon from '@components/Icons/Icon'
import Loader from '@components/Loader'
import Receipt from '@components/Icons/Receipt'
import PAYGUpgradeButton from '@components/Settings/PAYGUpgradeButton'
import PAYGDowngradeButton from '@components/Settings/PAYGDowngradeButton'

import { formatCurrencyWhole } from '@utils/currency'
import { formatDate } from '@utils/formatDate'
import { mapGetters } from 'vuex'
import { numberFormat } from '@utils/numberFormat'
import { fromUnixTime } from 'date-fns'

import plansApi from '@api/plans'
import states from '@api/states'

export default {
  components: {
    Icon,
    Loader,
    Receipt,
    PAYGUpgradeButton,
    PAYGDowngradeButton
  },

  data() {
    return {
      numberFormat,
      formatDate,
      fromUnixTime,

      states,
      error: null,

      // Cancelling variables
      showCancelModal: false,
      cancelInProgress: false,
      cancelErrorMessage: null,

      plans: null,
      selectedCurrency: null,

      currencies: [
        'GBP', 'EUR', 'USD', 'AUD'
      ]
    }
  },

  computed: {
    ...mapGetters({
      paygSubscription: 'subscriptions/paygSubscription'
    }),

    /**
     * @return {string}
     */
    state() {
      if (this.error) {
        return states.ERROR
      }
      if (!this.plans || !this.currency) {
        return states.LOADING
      }

      return states.READY
    },

    currency() {
      if (!this.paygSubscription) {
        return
      }
      return this.paygSubscription.fixed.currency
    },

    /**
     * Returns the plans but only includes prices for the currency selected
     *
     * @return {Array}
     */
    currencyPlans() {
      if (!this.currency) {
        return []
      }
      return this.plans.map(plan => {
        plan.products.fixed.price = plan.products.fixed.prices.find(price => price.currency === this.currency)
        plan.products.assessments.price = plan.products.assessments.prices.find(price => price.currency === this.currency)
        plan.products.candidates.price = plan.products.candidates.prices.find(price => price.currency === this.currency)

        return plan
      })
    }
  },

  mounted() {
    this.loadPlans()
  },

  methods: {
    /**
     * Load in products if missing
     */
    loadPlans() {
      plansApi.paygIndex()
        .then(response => {
          this.selectedCurrency = response.data.currencySuggestion
          this.plans = response.data.plans
        })
        .catch(error => {
          this.error = error
          throw error
        })
    },

    /**
     * @param {object} currencies
     * @return {string}
     */
    formatted(amount, currency) {
      if (!currency) {
        return amount / 100
      }
      return formatCurrencyWhole(amount / 100, currency)
    }
  }
}
</script>
